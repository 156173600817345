import type { ImpressionType } from '@/ImpressionType'

export const templateImpression: ImpressionType = {
  title: 'Title',
  impressionContent: `
          <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br />
          Praesent et consequat orci. Donec iaculis est et diam tempus imperdiet.<br />
          pharetra luctus interdum. Pellentesque purus justo, elementum ut enim quis, suscipit
          tincidunt tortor.<br />
          Donec sollicitudin bibendum dolor, id pharetra erat pharetra non.<br />
          Nunc ultricies aliquet quam id tempus. Nam euismod massa ligula, quis ultricies leo
          fringilla eget.<br />
          Maecenas ullamcorper congue nulla, ac porta neque sagittis volutpat.<br />
        </p>
  `,
  illustrationCaption: 'Impression caption',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impression-template-1.jpg',
  noteEntries: ['Stuff']
}
