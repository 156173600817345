<template>
  <div class="navbar">
    <div class="brand">
      <div class="logo">
        <a href="https://home.tuxcy.fr/">Tuxcy</a>
        <span v-for="entry in navEntries" :key="entry.id">
          | <a :href="entry.link">{{ utils.$i18n(entry.nameKey) }}</a>
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Ref } from 'vue'
import { inject, ref } from 'vue'
import type { ComponentUtils, NavigationEntry, NotesSitesStore } from '@tuxcy/types'

const store: NotesSitesStore = inject('Store')
const utils: ComponentUtils = inject('ComponentUtils')
const navEntries: Ref<NavigationEntry[]> = ref(store.getNavigationModule().entries)
</script>

<style scoped>
.brand {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.logo {
  height: 2rem;
  margin-right: 0.5rem;
}
.logo > img {
  width: 30px;
  border-radius: 50%;
  margin-left: 5px;
}

.navbar {
  background-color: black;
  width: 100%;
  height: 50px;
}

nav * {
  inline-size: calc(var(--width-navbar) - 10px);
  overflow-wrap: break-word;
}
</style>
