<template>
  <div class="navbar full-height">
    <brand-component></brand-component>
    <nav>
      <ul>
        <li v-for="entry in navEntries" :key="entry.id" class="entry-word-breaking">
          <sidebar-entry :entry="entry"></sidebar-entry>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script setup lang="ts">
import type { Ref } from 'vue'
import { inject, ref } from 'vue'
import type { ComponentUtils, NavigationEntry, NotesSitesStore } from '@tuxcy/types'
import BrandComponent from '@/components/brand/BrandComponent.vue'
import SidebarEntry from '@/components/sidebar/SidebarEntry.vue'

const store: NotesSitesStore = inject('Store')
const navEntries: Ref<NavigationEntry[]> = ref(store.getNavigationModule().entries)
</script>

<style scoped>
.navbar {
  position: fixed;
  display: block;
  width: var(--width-navbar);
  background-color: black;
  padding: 1rem;
  top: 0;
  left: 0;
}

nav * {
  inline-size: calc(var(--width-navbar) - 10px);
  overflow-wrap: break-word;
}
</style>
