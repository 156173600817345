import type { ImpressionType } from '@/ImpressionType'

export const impressionDataPlocPloc: ImpressionType = {
  title: 'Ploc ploc',
  impressionContent: `
<p>
C'est l'histoire d'une goute,<br/>
Qui se forme dans le ciel,<br/>
<br/>
Petite poussière,<br/>
Qui monte dans l'air,<br/>
Rencontre des amis,<br/>
Qu'un miracle emporte.<br/>
<br/>
Elle navigue au vent,<br/>
Suspendu là-haut,<br/>
A la recherche de gouttes d'eau.<br/>
<br/>
"Allons voir la mer !"<br/>
Et descendre le Rhône..<br/>
Sans frontière,<br/>
Serpente dans la vallée,<br/>
Se laissé porter.<br/>
<br/>
Au cours de ta vie, tu gonfles et t'agrèges,<br/>
Avec des amis de passage.<br/>
Installé dans les nuages<br/>
De poussière tu deviens pluie,<br/>
Goûte qui s'alourdies.<br/>
<br/>
Et un jour tu tombes,<br/>
Dans l'oubli que procure le nombre,<br/>
De tes frères et sœurs qui succombent,<br/>
Projeter sur un sol quelconque<br/>
Et dans un cliquetis,<br/>
Tu te détruit,<br/>
Mais projette la vie,<br/>
Petite pluie.<br/>
<br/>
Tu termines ton voyage,<br/>
Sur mes habits,<br/>
C'est une histoire de contact,<br/>
Un toboggan dément,<br/>
Qui nous projette un instant,<br/>
Toi et moi un samedi.<br/>
Petite pluie.<br/>
</p>
  `,
  illustrationCaption: 'Un peu de pluie ?',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/ploc-ploc-1.jpg',
  noteEntries: ['Sans parapluie, et près du Rhône']
}
