import type { ImpressionType } from '@/ImpressionType'

export const impressionDataTrainCrossroads: ImpressionType = {
  title: 'Voyage voyage...',
  impressionContent: `
<p>
Il est parfois de ces moments où l'ennuie se distant.
Un peu comme un voyage qui touche à sa fin, on se retrouve en mouvement.
On est alors sensible à tout, alerte, armè de notre expérience et de nos réflexes.
Chaque fois plus efficace, plus mécanique.
</p>
<p>
C'est ainsi pour lui, ruptures comme relations vont et s'enchaînent.
Une relation ? un trajet dont on sait dès le début qu'il prendra fin.
On ne sait certes jamais, avec exactitude, à quel moment les freins commenceront à hurler.
Les roues tranquillement se bloquer,
Et tout doucement, le train s'arrêter.
Mais on s'y attend et on le sait.
Les sentiments aussi, obéissent aux lois de la mécanique.
</p>
<p>
Après une quinzaine d'année à enchainer les relations plus ou moins longues Chris comprenez cette physique.
Il n'empêche que, tour à tour bourreau ou victime, il ressentait encore parfois son coeur se serrer, son ventre se nouer.
Cette douleur le rend encore humain.
Un poison corrosif, dévorant, mais salutaire.
</p>
<p>
C'était donc ça qui sommeillait sous le plancher de son quotidien ? Ce foyer toujours présent.
Manifestement, celui-ci était éteint dans les yeux de celle, en face, qui lui parlé.
Elle annoncé l'arrivée en gare, la fin du trajet, le début du transit.
Avec la gène, prégnante, que les mauvaises nouvelles ventillent.
Et l'écho, placide, du voyageur habitué déroulant l'annonce avant qu'elle ne soit terminé.
</p>
<p>
Malgré ce script attendu Chris ne pouvait s'empêcher de se voir spéctateur de ce moment.
Jusqu'au moment où la porte s'ouvre et où l'on doit déambuler sur le quai.
Veda, se lève pour faire le paquetage de son prochain trajet.
IL ne peut s'empêcher de prendre sa main, avec la délicatesse et l'hésitation des premiers jours.
</p>
<p>
"Encore un jour"
</p>
<p>
Les sourires se tracent, les voix se cassent, et les yeux s'implorent.
Et c'est parfois avec toute la douceur possible que l'on se quitte.
</p>
<p>
Les "petites morts" sont aussi des fins de trajet, suspendu entre deux mondes.  
</p>
`,
  illustrationCaption: 'Suspendu',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/train-station-2.jpg',
  noteEntries: ['Ahh les ruptures amoureuses !']
}
