import type { ImpressionType } from '@/ImpressionType'

export const impressionDataWordButton: ImpressionType = {
  title: 'Gratte bouton',
  impressionContent: `
          <p>
Bouton de mots,<br />
Qui gratte et démange,<br />
Avant que je ne mange,<br />
Cette pensée,<br />
Va m'énerver !<br />
<br />
Signe imagé,<br />
Une fois assemblé,<br />
Chacun y voit,<br />
Son propre reflet.<br />
<br />
Couperet,<br />
Qui parfois tranche,<br />
Le fil des réalités,<br />
Morcelé,<br />
Entre espoir et banalité,<br />
<br />
Joie ou tristesse,<br />
Qui parfois naissent<br />
Quand ils touchent,<br />
<br />
Et qui blessent,<br />
Autant qu'ils bercent.<br />
</p>
  `,
  illustrationCaption: 'Écriture publique',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/word-button-1.jpg',
  noteEntries: ['Souvenir Viennois']
}
