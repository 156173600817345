import type { ImpressionModule } from '@/store/modules/ImpressionModule'
import type ImpressionTemplate from '@/components/template/ImpressionTemplate.vue'

export class ImpressionComponent {
  constructor(private module: ImpressionModule) {}

  extractData(): ImpressionTemplate {
    return this.module.impressions[this.extractCurrentImpressionIndex()]?.data
  }

  extractNextIndex(): number {
    const possibleIndex = this.extractCurrentImpressionIndex() + 1
    return this.module.isLegitImpression(possibleIndex) ? possibleIndex : null
  }

  extractPreviousIndex(): number {
    const possibleIndex = this.extractCurrentImpressionIndex() - 1
    return this.module.isLegitImpression(possibleIndex) ? possibleIndex : null
  }

  extractRandomIndex(): number {
    return this.module.getRandomImpression().id
  }

  extractCurrentImpressionIndex(): number {
    const search = window.location.search
    const splitPath = search.split('=')
    return splitPath[0] !== '' && Number(splitPath[1]) != null
      ? Number(splitPath[1])
      : this.module.impressions.length - 1
  }
}
