<template>
  <div class="article-container">
    <h1>A propos</h1>
    <div>
      <article class="tl-dr">
        <aside>
          <p><b>Qui suis-je ?</b></p>
        </aside>
        <div>
          <p class="text-content">
            Cyril RICHARD (<i>aka</i> Tuxcy), un développeur français, qui écrit très souvent
            n'importe quoi.
          </p>
        </div>
      </article>
      <article class="tl-dr">
        <aside>
          <p><b>Pourquoi j'écris ça ?</b></p>
        </aside>
        <div>
          <p class="text-content">
            J'écris tout le temps plein de truc, du code, des textes divers, de longs mails... Ici
            je partage des textes spontanée que j'écris n'importe où, n'importe quand et surtout
            n'importe comment. Particulièrement pour mes proches.
          </p>
        </div>
      </article>
      <article class="tl-dr">
        <aside>
          <p><b>Des "impressions" ?</b></p>
        </aside>
        <div>
          <p class="text-content">
            La terme m'est venu un soir de pluie au parc de la tête d'or. Il désigne pour moi un
            texte qui exprime une sensation, un instant, donc souvent bordélique, et parfois
            retravaillé.
          </p>
        </div>
      </article>
      <article class="tl-dr">
        <aside>
          <p><b>Quelle licence ?</b></p>
        </aside>
        <div>
          <p class="text-content">
            <a rel="license" href="http://creativecommons.org/licenses/by/2.5/"
              ><img
                alt="Creative Commons License"
                style="border-width: 0"
                src="https://i.creativecommons.org/l/by/2.5/88x31.png" /></a
            ><br />Ce travail utilise la licence
            <a rel="license" href="http://creativecommons.org/licenses/by/2.5/"
              >Creative Commons Attribution 2.5 Generic License</a
            >.
          </p>
        </div>
      </article>
    </div>
  </div>
</template>
<script lang="ts" setup></script>

<style scoped>
aside {
  border-left: 4px solid blue;
}
.article-container {
  width: calc(100% - 50px);
  padding-bottom: 20px;
}
.text-content {
  padding: 0.5rem 0px;
}
</style>
