import type { ImpressionType } from '@/ImpressionType'

export const impressionDataHopeBubble: ImpressionType = {
  title: 'BuBulle !',
  impressionContent: `
          <p>
Instant de vie,<br/>
Raisonne au bruit,<br/>
D'un cœur qui bat,<br/>
Dans un ébat.
        </p>
          <p>
Funambule,<br/>
Rêve de bulle,<br/>
Qui éclate,<br/>
Sans contact.
        </p>
          <p>
Ou flotte,<br/>
Monte,<br/>
Porte, <br/>
Espoir et envie,<br/>
Dans un ciel infini.
        </p>
          <p>
Instant présent,<br/>
A cheval sur deux "mois",<br/>
Deux projections de soi,<br/>
Qui s'enlace ou s'emmêle,<br/>
S'enroule ou déchire,<br/>
Allume ou éteint,<br/>
Le foyer de l'envie,<br/>
Braise d'une vie.
        </p>
  `,
  illustrationCaption: 'Bubble forest !',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/forest-bubble-1.jpg',
  noteEntries: ['5 minutes sur les routes de Croatie']
}
