import type { ImpressionType } from '@/ImpressionType'

export const impressionDataAuRevoir: ImpressionType = {
  title: 'Au revoir',
  impressionContent: `
          <p>
Parfum des îles,<br/>
Saveur vanille,<br/>
Cheveux soyeux,<br/>
Éclat heureux.<br/>
<br/>
Instant de bulle,<br/>
Braise qui fume,<br/>
Au milieu de la foule,<br/>
On s'enroule.<br/>
<br/>
Élan biaisé,<br/>
Baisé damné,<br/>
Un soir d'été,<br/>
On s'est quittés.<br/>
<br/>
Rire et peur,<br/>
Sans rancœur,<br/>
Cœur contre cœur,<br/>
Quel malheur...<br/>
<br/>
Mourir de bonheur,<br/>
Sourire qui pleure,<br/>
Ivresse et deuil,<br/>
Comme un linceul,<br/>
<br/>
Et tout éclate,<br/>
Plus de contact,<br/>
Le temps nous fend,<br/>
En un instant,<br/>
<br/>
.. Écoeurant ..<br/>
<br/>
Maintenant,<br/>
Au revoir,<br/>
Ni toi, ni moi <br/>
Oublierons<br/>
Nos prénoms.<br/>
<br/>
...<br/>
<br/>
Je m'éveille,<br/>
Rêve sans ailes,<br/>
Happé par l'instant,<br/>
Ou l'on tenait le temps...<br/>
        </p>
  `,
  illustrationCaption: 'Bulle',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/au-revoir-2.jpg',
  noteEntries: ['...Hotel de ville...']
}
