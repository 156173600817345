import type { ImpressionType } from '@/ImpressionType'

export const impressionDataCapsule: ImpressionType = {
  title: 'Capsules',
  impressionContent: `
<p>
Petit Poucet,<br/>
Je sème sur mon trajet,<br/>
Des galets de papier.<br/>
<br/>
Catharsis,<br/>
J'écris et oublis,<br/>
Comme une thérapie.<br/>
Qui me tient éveillé,<br/>
M'accroche aux réalités.<br/>
<br/>
Pavet,<br/>
Où j'espère un jour te voir marcher,<br/>
Remonter le fil de ma vie,<br/>
Avec envie !<br/>
<br/>
Cerclé de verre,<br/>
Jolie repère,<br/>
Qui, sincère,<br/>
Navigue depuis le présent,<br/>
Traverse le temps.<br/>
<br/>
Bulle de moi,<br/>
Protéiforme,<br/>
Tantôt lisse et réfléchis<br/>
Tantôt peur où envie,<br/>
Parfois désire et oui, rire !<br/>
Même ! moment d'abandon,<br/>
Comme ces "impressions"..<br/>
<br/>
Certains galets, moins reluisant,<br/>
Montre un autre versant,<br/>
Celui sale, repoussant,<br/>
Que l'on ne veut voir,<br/>
Se complet dans le noir,<br/>
Hésite à modeler,<br/>
A graver sur papier.<br/>
<br/>
Pourtant il faut oser,<br/>
Se montrer tel que l'on est,<br/>
Accepter ses sentiments,<br/>
Même les plus mordants.<br/>
<br/>
... que c'est chiant ! ...<br/>
<br/>
Ainsi est ma nouvelle arme,<br/>
Ces mots qui me gagnent,<br/>
Que j'espère dans un émoi,<br/>
Partager, un jour, avec toi.<br/>
</p>
  `,
  illustrationCaption: 'Life track',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/petit-poucet-1.jpg',
  noteEntries: ['Au saut du lit']
}
