import type { ImpressionType } from '@/ImpressionType'

export const impressionDataBratMuse: ImpressionType = {
  title: 'Brats',
  impressionContent: `
        <p>
          Muse, Buse,<br />
          Qui m'amuse,<br />
          Défile nue à ma vue,<br />
          Et assure :<br />
          "Ne pourriez-vous faire ce que vous voulez de moi ?<br />
          Me dresser, me languir, me fouetter ou m'aimer ?<br />
          Mais pour cela... il faudra...<br />
          que vous mettiez la main sur moi !"<br />
        </p>
  `,
  illustrationCaption: "Brat's glance",
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/brat-1.jpg',
  noteEntries: ['Inspiré par des oies qui défilèrent devant moi.']
}
