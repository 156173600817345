import type { ImpressionType } from '@/ImpressionType'

export const impressionDataEscalier: ImpressionType = {
  title: 'Escalier',
  impressionContent: `
<p>
Avoir de l'égard,<br/>
Pour un regard,<br/>
Qu'on appelle au détour,<br/>
Du parvis d'une tour.<br/>
<br/>
Souvenir enfoui,<br/>
Qu'on rappelle à la vie !<br/>
<br/>
Et qui place,<br/>
Sur mes joues lasses,<br/>
Un sourire attendrit,<br/>
Un instant de vie.<br/>
<br/>
Transformer,<br/>
Une rampe d'escalier,<br/>
En endroit sacré,<br/>
<br/>
Baptisé,<br/>
Par des enfants,<br/>
Bruyants, chantants !<br/>
<br/>
Je me souviens...<br/>
Nos regards qui s'emmêlent,<br/>
Sensations irréelles,<br/>
Que dans le lointain,<br/>
Nous ne ferons qu'un.
</p>
  `,
  illustrationCaption: 'Escalier fleurit !',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/escalier-1.jpg',
  noteEntries: ["Écrit égaré... c'est un peu le bordel"]
}
