export enum ImpressionsEnum {
  // impressions
  IMP_BRAT_MUSE = 'imp-brat-muse',
  IMP_CHILD = 'imp-child',
  IMP_INTRODUCTION = 'imp-introduction',
  IMP_PLANE = 'imp-plane',
  IMP_PLANE_PRODUCTIVITY = 'imp-plane-productivity',
  IMP_TRAIN_CROSSROADS = 'imp-train-crossroads',
  IMP_HOPE_BUBBLE = 'imp-hope-bubble',
  IMP_WORD_BUTTON = 'imp-word-button',
  IMP_COUPLE_HOLIDAY = 'imp-couple-holiday',
  IMP_AU_REVOIR = 'imp-au-revoir',
  IMP_BOULIMIE = 'imp-boulimie',
  IMP_CAPSULE = 'imp-capsule',
  IMP_MONOLOG = 'imp-monolog',
  IMP_PLOC_PLOC = 'imp-ploc-ploc',
  IMP_POINTILLE = 'imp-pointille',
  IMP_COLOC = 'imp-coloc',
  IMP_SAC_DE_NOEUD = 'imp-sac-de-noeud',
  IMP_ERUPTION = 'imp-eruption',
  IMP_ESCALIER = 'imp-escalier',
  // pages
  WELCOME = 'welcome-pages',
  ABOUT = 'about-page',
  NOT_FOUND = 'not-found',
  // templates
  IMPRESSION_TEMPLATE = 'impression-template'
}
