import './assets/index.css'

import { createApp } from 'vue'
import { AppStoreImpl } from '@/store/AppStoreImpl'
import { registerImpressions, registerUI } from '@/components'
import HomePage from '@/pages/ImpressionPage.vue'
import { MockedComponentUtils } from '@/mock/MockedComponentUtils'

const defaultPage = HomePage
const app = createApp(defaultPage)
const store = new AppStoreImpl()

app.provide('Store', store)
app.provide('ComponentUtils', new MockedComponentUtils())
registerImpressions(store)
registerUI(app, store)
app.mount('#app')
