<template>
  <div class="article-container">
    <impression-navigation-component
      :next-index="cpn.extractNextIndex()"
      :prev-index="cpn.extractPreviousIndex()"
      :random-index="cpn.extractRandomIndex()"
    >
    </impression-navigation-component>
    <h1 class="center">{{ impression.title }}</h1>
    <div>
      <article>
        <article>
          <image-component
            :src="impression.illustrationPath"
            :caption="impression.illustrationCaption"
            :copyright="impression.illustrationCopyright"
            width="512px"
          >
          </image-component>
        </article>
      </article>
      <article class="impression-content">
        <div v-html="impression.impressionContent"></div>
      </article>

      <article class="tl-dr">
        <aside>
          <p>Notes</p>
        </aside>
        <div>
          <ul class="notes-content" v-if="impression.noteEntries && impression.noteEntries.length">
            <li v-for="entry in impression.noteEntries">
              <i>{{ entry }}</i>
            </li>
          </ul>
        </div>
      </article>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref } from 'vue'
import ImageComponent from '@/components/image/ImageComponent.vue'
import { ImpressionComponent } from '@/utils/ImpressionComponent'
import type { ImpressionAppStore } from '@/store/ImpressionAppStore'

const store: ImpressionAppStore = inject('Store')
const cpn = new ImpressionComponent(store.getImpressionModule())
const impression = ref(cpn.extractData())
</script>

<style scoped>
.article-container {
  width: calc(100% - 50px);
  padding-bottom: 20px;
}

.impression-content {
  text-align: center;
  padding: 15px;
  line-height: 2em;
  font-style: italic;
}

.notes-content {
  font-style: italic;
}
</style>
