<template>
  <div class="article-container center">
    <h1>Nothing Here</h1>
  </div>
  <div>
    <image-component src="/img/stop-1.jpg" caption="Mistakes happen :)" width="512px">
    </image-component>
  </div>
</template>
<script lang="ts" setup>
import type { Ref } from 'vue'
import { ref } from 'vue'
import TldrComponent from '@/components/tldr/TldrComponent.vue'
import type { Keypoint } from '@tuxcy/types'
import ImageComponent from '@/components/image/ImageComponent.vue'

const code = ref(`<div>
    <a>Link</a>
</div>
`)
</script>

<style scoped>
.article-container {
  width: calc(100% - 50px);
  padding-bottom: 20px;
  color: red;
}
</style>
