import type { ImpressionType } from '@/ImpressionType'

export const impressionDataEruption: ImpressionType = {
  title: 'Petite éruption',
  impressionContent: `
<p>
Mouvement de recul<br/>
Où tout bascule,<br/>
Quand on saute la rambarde,<br/>
Qu'on déambule hagard,<br/>
Dans le dédale de soi.<br/>
<br/>
Nos limites ? On les hurle et détruit,<br/>
C'est une embardée.<br/>
Nos repaires ? Non rien.<br/>
On ne sent plus rien et tout a la fois.<br/>
On cherche l'air ou le regard.<br/>
L'acceptation, l'amour, une chaleur.<br/>
Mais c'est l'écho qui nous répond.<br/>
Le froid, l'effroi, le verglas.<br/>
<br/>
C'est une éruption, presque de bouton,<br/>
Une démangeaisons qui s'étend.<br/>
On se vide de nous même,<br/>
Une contraction d'angoisse,<br/>
Qui éclate dans un sanglot...<br/>
<br/>
Qu'est-ce qui nous ramène à nous ?<br/>
Parfois rien, ou juste une autre douleur.<br/>
Notre boussole ? Quelques choses que l'on maîtrise.<br/>
Hier une lame qui nous coupe, aujourd'hui des griffes sur la peau.<br/>
Demain ? Non rien un cœur déjà en lambeaux.<br/>
<br/>
Après les pleurs, après ces contorsions,<br/>
Après cette vomissure de peur, et d'anxiété,<br/>
Les ronronnements ménagés,<br/>
Loin de m'apaiser,<br/>
Inscrive ma solitude, dans un comfort douillé.<br/>
<br/>
On se demande parfois a quoi bon,<br/>
Être une barque sans cesse ramenée vers son passé.<br/>
J'aimerai juste que l'on me prenne et qu'on m'aime.<br/>
A en perdre ni l'haleine ni l'espoir.<br/>
<br/>
Mais c'est juste un mot écrit dans le noir, tard, tard le soir.<br/>
Terne et blafard.<br/>
Sans écho, sans rien.<br/>
Sans rien. Sans rien.<br/>
<br/>
...<br/>
<br/>
Rythme haché, je respire,<br/>
Dans un brouillard de larme,<br/>
Mes mots glissent, désarme.<br/>
<br/>
Funambule fragile...
</p>
  `,
  illustrationCaption: 'Heureusement, volcan éteint !',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/eruption-1.jpg',
  noteEntries: ['Encore une bonne nuit !']
}
