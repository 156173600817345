import type { NavigationModule, RoutingContext, RoutingModule } from '@tuxcy/types'
import { ImpressionsEnum } from '@/components/impressions/ImpressionsEnum'
import type { ImpressionAppStore } from '@/store/ImpressionAppStore'
import type { ImpressionEntry, ImpressionModule } from '@/store/modules/ImpressionModule'
import { templateImpression } from '@/impressions/TemplateImpression'

export class AppStoreImpl implements ImpressionAppStore {
  private readonly navigationModule: NavigationModule
  private readonly routingModule: RoutingModule
  private readonly impressionModule: ImpressionModule

  constructor() {
    this.navigationModule = {
      entries: [
        {
          id: 'naventry-impressions',
          // link: (ctx) => {
          //   return '/?impressions=news'
          // },
          link: '/?impression=0',
          nameKey: 'app.impressions.naventry.impressions'
        },
        {
          id: 'naventry-about',
          link: '/?about',
          nameKey: 'app.impressions.naventry.about'
        }
      ]
    }
    this.routingModule = {
      routes: [
        // {
        //   id: 'route-entry-0',
        //   enable: (ctx) => {
        //     return true
        //   },
        //   target: () => ImpressionsEnum.NOT_FOUND
        // },
        {
          id: 'route-entry-1',
          enable: (ctx) => {
            const pathname = ctx.location.pathname
            const search = ctx.location.search
            return pathname === '/' && !search
          },
          target: (ctx) => {
            const entry =
              this.impressionModule.impressions[this.impressionModule.impressions.length - 1]
            return entry.alias as ImpressionsEnum
          }
        },
        {
          id: 'route-entry-2',
          enable: (ctx) => {
            const search = ctx.location.search
            return search === '?about'
          },
          target: () => ImpressionsEnum.ABOUT
        },
        {
          id: 'route-entry-3',
          enable: (ctx) => {
            const search = ctx.location.search
            const splitPath = search.split('=')
            const index = Number(splitPath[1])
            return Number.isInteger(index) && this.impressionModule.isLegitImpression(index)
          },
          target: (ctx) => {
            const search = ctx.location.search
            const splitPath = search.split('=')
            const index = Number(splitPath[1])
            const entry = this.impressionModule.impressions[index]
            return entry.alias as ImpressionsEnum
          }
        },
        {
          id: 'route-entry-4',
          enable: (ctx) => {
            return true
          },
          target: () => ImpressionsEnum.NOT_FOUND
        }
      ],
      findEntry(ctx: RoutingContext) {
        return this.routes.find((entry) => entry.enable(ctx))
      }
    }
    this.impressionModule = {
      currentImpression: templateImpression,
      impressions: [],
      latestRandoms: [],
      registerImpression(entry: ImpressionEntry) {
        this.impressions.push(entry)
      },
      getRandomImpression(): ImpressionEntry {
        let randomImpression = null
        do {
          const index = Math.floor(Math.random() * this.impressions.length)
          randomImpression = this.impressions[index]
        } while (this.latestRandoms.includes(randomImpression))

        if (this.latestRandoms.length >= 4) {
          this.latestRandoms.shift()
          this.latestRandoms.shift()
        }

        this.latestRandoms.push(randomImpression)
        return randomImpression
      },
      isLegitImpression(id: number): boolean {
        return !!this.impressions[id]
      }
    }
  }

  getNavigationModule(): NavigationModule {
    return this.navigationModule
  }

  getRoutingModule(): RoutingModule {
    return this.routingModule
  }

  getImpressionModule(): ImpressionModule {
    return this.impressionModule
  }

  getStore() {
    return this
  }
}
