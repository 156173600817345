<template>
  <div class="center">
    <button
      class="nav-button"
      v-if="props.prevIndex != null"
      @click="changeImpression(props.prevIndex)"
    >
      <span class="chevron left"></span>
    </button>
    <button class="nav-button" @click="changeImpression(props.randomIndex)">Aléatoire</button>
    <button
      class="nav-button"
      v-if="props.nextIndex != null"
      @click="changeImpression(props.nextIndex)"
    >
      <span class="chevron right"></span>
    </button>
  </div>
</template>
<script setup lang="ts">
import type { ComponentUtils } from '@tuxcy/types'
import { inject } from 'vue'

const props = defineProps<{
  nextIndex: number | null
  randomIndex: number
  prevIndex: number | null
}>()

const utils: ComponentUtils = inject('ComponentUtils')

function changeImpression(storyIndex: number) {
  window.location.href = window.location.origin + '/?impression=' + storyIndex
}
</script>

<style scoped>
.nav-button {
  line-height: 1rem;
  padding: 0.5rem 1rem;
  margin: 0px 10px;
}
</style>
