<template>
  <div class="container main-container full-height">
    <lateral-navbar-component class="lateral-navbar"></lateral-navbar-component>
    <header-navbar-component class="header-navbar"></header-navbar-component>
    <main class="main-content full-width">
      <component :is="component"></component>
    </main>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue'
import { inject, ref } from 'vue'
import type { NotesSitesStore } from '@tuxcy/types'
import LateralNavbarComponent from '@/components/sidebar/LateralNavbarComponent.vue'
import HeaderNavbarComponent from '@/components/sidebar/HeaderNavbarComponent.vue'

const store: NotesSitesStore = inject('Store')

const routingContext = {
  location: window.location
}
const routingEntry = store.getRoutingModule().findEntry(routingContext)
const component: Ref<string> = ref(routingEntry?.target(routingContext) || 'welcome-notes-sites')
const data: Ref<string> = ref(routingEntry?.target(routingContext) || 'welcome-notes-sites')
</script>

<style scoped></style>
