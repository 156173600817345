import type { ImpressionType } from '@/ImpressionType'

export const impressionDataChild: ImpressionType = {
  title: 'Petit M',
  impressionContent: `
        <p>
          Moments riant,<br />
          Visage d'enfant,<br />
          Sans soucis, cœur léger.<br />
          Les genoux écorchés.<br />
          <br />
          Mais qui crie !<br />
          "A la vie ?"<br />
          Non la nuit !<br />
          Pour sa mère,<br />
          Sans un père.<br />
          Lui pépère.<br />
          <br />
          Petite âme,<br />
          Aux joues pleines,<br />
          Comme ses aînés<br />
          Addict à la télé !<br />
          <br />
          Dans un rire,<br />
          Regard d'océan,<br />
          Une note de tristesse,<br />
          Qui déjà perce.<br />
          <br />
          Si jeune mais déjà,<br />
          La conscience qu'il ne verra,<br />
          Que rarement son papa,<br />
          <br />
          Mais voilà qu'il court !<br />
          Espiègle, après ses jouets !<br />
          Voir parfois le chien aux regards défait..<br />
          Dans la chaleur d'un foyer,<br />
          Recomposé.<br />
          <br />
          Il n'est finalement pas si vieux !<br />
          Ce petit Monsieur ^^<br />
        </p>
  `,
  illustrationCaption: 'Holidays !',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/children-beach-3.jpg',
  noteEntries: ["C'est clair non ?"]
}
