import type { ImpressionType } from '@/ImpressionType'

export const impressionDataCoupleHolidays: ImpressionType = {
  title: 'Vacances...',
  impressionContent: `
          <p>
Pas à pas et trépas,<br/>
Trop loin et trompé,<br/>
Sur le chemin qui m'amène au gai !<br/>
"Pas par là !"<br/>
Mais pourquoi ?<br/>
Ce ne sont que des pas,<br/>
Et marcher, me fait rêver.<br/>
<br/>
"Ou diable veux tu allez !!?"<br/>
Je ne sais pas,<br/>
Mais guide moi,<br/>
Toi qui d'en haut.<br/>
M'en mets toujours plein le dos.<br/>
<br/>
"Il faudrait que.."<br/>
Que la tête me tombe ?<br/>
Que l'on trouve de l'ombre ?<br/>
Que l'on sorte du nombre !?<br/>
<br/>
Mais pourquoi donc s'imposer,<br/>
Tant de simagrées ?<br/>
On veut partager !<br/>
Non pas des pas,<br/>
Mais nos pensées à soi.<br/>
<br/>
Et pouvoir,<br/>
Tard le soir, <br/>
Se coucher, repu, épuisé.<br/>
Calmé ! la fureur de penser.<br/>
Et avoir pour mélodie,<br/>
Jusqu'au cœur de la nuit,<br/>
Endormie, ma fée, partenaire allongée,<br/>
Allègrement dénudée.<br/>
<br/>
Ahhh si seulement elle avait, <br/>
Entre autres qualités, <br/>
Celle de la fermer !<br/>
        </p>
  `,
  illustrationCaption: 'Petite dispute ?',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/couple-holidays-1.jpg',
  noteEntries: ['Vite écris avant de partir au travail', 'Ça sent le vécu !']
}
