import { FR_I18N_KEYS } from '@/assets/i18n/fr'
import type { ComponentUtils } from '@tuxcy/types'

export class ComponentUtilsImpl implements ComponentUtils {
  protected readonly LANG: string
  protected readonly I18N_KEYS: { [key: string]: { [key: string]: string } } = {
    en: FR_I18N_KEYS
  }

  constructor() {
    this.LANG = extractNavLang()
  }

  $i18n(key: string): string {
    const langKeys = this.I18N_KEYS[this.$lang()]
    return (langKeys ? langKeys[key] : key) || key
  }

  $lang(): string {
    return this.LANG.toLowerCase()
  }
}

function extractNavLang() {
  return navigator.language.split('-')[0]
}
