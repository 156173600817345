<template>
  <div class="brand">
    <div class="logo">
      <a href="https://home.tuxcy.fr/">Tuxcy</a>
      |
      <a href="/">IMPRESSIONS</a>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped>
.brand {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.logo {
  height: 2rem;
  margin-right: 0.5rem;
}
.logo > img {
  width: 30px;
  border-radius: 50%;
  margin-left: 5px;
}
</style>
