import type { ImpressionType } from '@/ImpressionType'

export const impressionDataPlaneProductivity: ImpressionType = {
  title: 'Work Work Work Work',
  impressionContent: `
        <p>
          Tic-tac, tic-tac,<br />
          C'est le clavier que l'on frappe,<br />
          Sans perdre un instant,<br />
          Le temps c'est de l'argent.<br />
          <br />
          Moment de rêverie,<br />
          Qui en pâtit.<br />
          Bande d'abrutis !! ...<br />
          <br />
          Pourquoi courir après,<br />
          La moindre faute de clavier,<br />
          Quand on a connu le papier ?<br />
          <br />
          Il est certes vrai,<br />
          Que je fais partie,<br />
          De ces abrutis,<br />
          Jamais gratte papier,<br />
          Toujours optimisé,<br />
          A parer aux plus pressées.<br />
          <br />
          Mais quelle allégresse,<br />
          De suivre sans paresse,<br />
          Une agile pensée,<br />
          Parfois mal ciselé,<br />
          <br />
          Et qui se lisse,<br />
          Puis glisse,<br />
          Sans malice,<br />
          Jusqu'à tracé,<br />
          Un sourire satisfait.<br />
          Plaisir parfait,<br />
          D'un texte huilé.<br />
          <br />
          Et quelle tristesse,<br />
          Quand pareille prouesse,<br />
          Asservis l'auteur,<br />
          Outils moteurs,<br />
          De cette ère terne<br />
          Ahh.. monde "Moderne" !<br/>
          <br/>
          Mais le temps presse !<br/>
          "Vite, vite ! Il faut finir ce mail !"<br/>
          Même, compresser par dizaines.<br />
          De corps et d'esprit,<br />
          Dans un avion qui atterrit !!?<br />
        </p>
  `,
  illustrationCaption: 'Overworking human form',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/working-plane-1.jpg',
  noteEntries: [
    `Écrit dans un avion, bercé par le cliquetis des claviers des deux workings men / women
    à mes côtés.`,
    `Et repris un peu plus tard... parce que la fin était encore pire que maintenant !`
  ]
}
