import type { ImpressionType } from '@/ImpressionType'

export const impressionDataSacDeNoeud: ImpressionType = {
  title: 'Sac de noeud',
  impressionContent: `
<p>
Moment de grâce, que le temps enlace,<br/>
Comme nos mains timides qui se joignent,<br/>
Expressions distillées de notre attirance,<br/>
Réaction au supplice que l'on nomme absence.<br/>
<br/>
C'est des liens qui se tissent,<br/>
Comme des lianes qui se joignent,<br/>
Filet de sécurité entre deux arbres,<br/>
Qui nous empêche d'heurter le marbre.<br/>
<br/>
Moment de tension, que l'on subit,<br/>
De passion, amoindris,<br/>
Quand des rires, on passe aux larmes,<br/>
Touché en plein cœur, par nos peurs., nos drames.<br/>
<br/>
Se sentir brisé, concassé,<br/>
Par notre passé, nos fissures.<br/>
Ce dire que l'on ne peut vivre le plus pur.<br/>
Tenter l'aventure, ensemble dans la nature.<br/>
Reposer sur l'autre notre être.<br/>
<br/>
La peur prend au ventre,<br/>
Ces mouvements seraient-ce des pansements ?<br/>
Comme une infirmière apporte le réconfort,<br/>
Pour un mourant qu'une dernière fois on endort ?<br/>
<br/>
Non non non,<br/>
Il faut se battre contre la peur,<br/>
Trou noir qui aspire le bonheur,<br/>
Il faut lever la tête, regarder les comètes,<br/>
Être une étoile qui brille,<br/>
Scintille, illumine,<br/>
Comme tes yeux dans la nuit,<br/>
Qui luise devant moi,<br/>
Quand je te prends contre moi.<br/>
<br/>
A ces étreintes qu'on se donne,<br/>
Comme une musique qui raisonne,<br/>
C'est dans la distance que naît l'écho,<br/>
C'est dans le temps que raisonne nos mots.<br/>
<br/>
J'aimerai briller avec toi,<br/>
M'arraché à la peur, te prendre contre moi,<br/>
Me détacher du sol comme une fusée,<br/>
Pour lentement perdre pied,<br/>
De nos vies concassées en faire un foyer.<br/>
Où nous aurons tous loisir de nous retrouver.<br/>
Ensemble a la nuit tombée,<br/>
Et contemplé enlacé,<br/>
Les épreuves que l'on a traversé,<br/>
L'avec l'autre a nos côtés.<br/>
<br/>
...<br/>
<br/>
Laisse moi rêver... je suis bourré !<br/>
</p>
  `,
  illustrationCaption: 'Sac de noeud',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/sac-de-noeud-1.jpg',
  noteEntries: [`Que c'est niais ... mais vrai !`, `...Je ne devrais pas écrire ça...`]
}
