import type { ImpressionType } from '@/ImpressionType'

export const impressionDataPlane: ImpressionType = {
  title: 'Cloudy cotton',
  impressionContent: `
        <p>
          Duveuteuse image,<br />
          Cotton flottant,<br />
          Immuable et banal,<br />
          Balladé par les vents.<br />
          <br />
          Fuselage pénétrant,<br />
          Defleurant à l'envie,<br />
          Ce voile vierge,<br />
          Qui dans le ciel gamberge.<br />
          <br />
          Nos sens s'enferment,<br />
          Dans une carcasse d'acier,<br />
          Qui n'a pour utilité,<br />
          Celle de voler.<br />
          <br />
          Et mes yeux caressent,<br />
          Cette beauté que l'on blesse,<br />
          Inaccessible et mortel,<br />
          Comme une femme de sel.<br />
        </p>
  `,
  illustrationCaption: 'Flying stuff',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/sky-with-planes-2.jpg',
  noteEntries: ['Écrit dans un avion bourdonnant fendant un nuage.. vive les hublots !']
}
