import type { ImpressionType } from '@/ImpressionType'

export const impressionDataPointille: ImpressionType = {
  title: 'Pointillés',
  impressionContent: `
<p>
De ces moments de silence,<br/>
Qui s'inscrivent, sur la pointe des pieds,<br/>
Délicate approche,<br/>
Ou le vent me porte,<br/>
Avant de retomber.<br/>
<br/>
Tu t'inscris en pointillé,<br/>
Comme une brise,<br/>
Un vent frais,<br/>
Qui fend la fournaise,<br/>
Éloigne le malaise.<br/>
Redonne espoir,<br/>
Tard le soir.<br/>
<br/>
On reprend de l'altitude,<br/>
Gonfle la voile,<br/>
Enjambe les silences,<br/>
Comme ces jeux d'enfants,<br/>
Ou l'on saute, de pierre en pierre,<br/>
Sans toucher le sable.<br/>
Sans glisser sur l'algue.<br/>
<br/>
Moment d'apesanteur,<br/>
Et de déséquilibre,<br/>
Qui, sans nous rendre libre,<br/>
Nous inscrit quelque part.<br/>
Ni "loin" ni "près",<br/>
Ni même "prêt".<br/>
<br/>
Comme une autre dimension,<br/>
Comme un rêve,<br/>
Un espoir,<br/>
Une illusion ?<br/>
Peut être qu'un trait,<br/>
Imparfait,<br/>
...<br/>
Je ne sais pas dessiner !<br/>
<br/>
Mais ses courbes m'inspire..<br/>
Ton corps que je contemplais,<br/>
Dans l'ombre des mâtinées,<br/>
Voilà bientôt une année.<br/>
Que c'est vite passé.<br/>
<br/>
...<br/>
<br/>
Je me raccroche moi,<br/>
A des morceaux de toi,<br/>
Que l'on sème,<br/>
Tour à tour,<br/>
Espérant peut être,<br/>
Un jour en paix,<br/>
Raconter,<br/>
Ces petites enjambées.<br/>
<br/>
...<br/>
<br/>
Je m'en vais rêver. <br/>
</p>
  `,
  illustrationCaption: 'Saute rocher',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/pointille-1.jpg',
  noteEntries: ['Inspiration nocturne', "Merci à Quang Nguyen Vinh pour l'image originel"]
}
