<template>
  <div>
    <a :href="entry.link">{{ utils.$i18n(entry.nameKey) }}</a>
    <div v-if="hasChildren">
      <button class="button-collapse" v-if="!clickedEntry" @click="clickedEntry = !clickedEntry">
        +
      </button>
      <button class="button-collapse" v-if="clickedEntry" @click="clickedEntry = !clickedEntry">
        -
      </button>
    </div>
    <ul v-if="hasChildren">
      <li v-for="subEntry in entry.children" :key="subEntry.id" class="entry-word-breaking">
        <sidebar-entry :entry="subEntry"></sidebar-entry>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import type { NavigationEntry } from '@tuxcy/types'
import type { ComponentUtils } from '@tuxcy/types'
import type { Ref } from 'vue'
import { inject, ref } from 'vue'

const props = defineProps<{ entry: NavigationEntry }>()

const utils: ComponentUtils = inject('ComponentUtils')
const hasChildren: Ref<boolean> = ref(!!props.entry.children?.length)
const clickedEntry: Ref<boolean> = ref(false)
</script>

<style scoped>
.button-collapse {
  padding: 0;
  margin: 0;
}
</style>
