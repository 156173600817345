<template>
  <div class="article-container">
    <h1>Impressions</h1>
    <ul>
      <li>Keyword1</li>
      <li>Keyword2</li>
    </ul>
    <tldr-component :points="keypoints"></tldr-component>
    <div>
      <h2>Why ?</h2>
      <article>
        <h3>News</h3>
        <article>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et consequat orci.
            Donec iaculis est et diam tempus imperdiet. Pellentesque pharetra luctus interdum.
            Pellentesque purus justo, elementum ut enim quis, suscipit tincidunt tortor. Donec
            sollicitudin bibendum dolor, id pharetra erat pharetra non. Nunc ultricies aliquet quam
            id tempus. Nam euismod massa ligula, quis ultricies leo fringilla eget. Maecenas
            ullamcorper congue nulla, ac porta neque sagittis volutpat.
          </p>
          <image-component
            src="/img/image-exemple.jpg"
            caption="An application C4"
            copyright="Source"
            width="600px"
          >
          </image-component>
        </article>
      </article>
      <article>
        <h3>What will I find ?</h3>
        <article>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et consequat orci.
            Donec iaculis est et diam tempus imperdiet. Pellentesque pharetra luctus interdum.
            Pellentesque purus justo, elementum ut enim quis, suscipit tincidunt tortor. Donec
            sollicitudin bibendum dolor, id pharetra erat pharetra non. Nunc ultricies aliquet quam
            id tempus. Nam euismod massa ligula, quis ultricies leo fringilla eget. Maecenas
            ullamcorper congue nulla, ac porta neque sagittis volutpat.
          </p>
          <p>Here is an exemple :</p>
          <code-component lang="html" :code="code"></code-component>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et consequat orci.
            Donec iaculis est et diam tempus imperdiet. Pellentesque pharetra luctus interdum.
            Pellentesque purus justo, elementum ut enim quis, suscipit tincidunt tortor. Donec
            sollicitudin bibendum dolor, id pharetra erat pharetra non. Nunc ultricies aliquet quam
            id tempus. Nam euismod massa ligula, quis ultricies leo fringilla eget. Maecenas
            ullamcorper congue nulla, ac porta neque sagittis volutpat.
          </p>
          <p>And another one :</p>
          <code-component lang="bash" :code="'ls -all'"></code-component>
          <code-component lang="bash" :code="'ls -all'"></code-component>
        </article>
      </article>
      <article>
        <h3>Sources</h3>
        <ul>
          <li><a>Link1</a></li>
        </ul>
      </article>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { Ref } from 'vue'
import { ref } from 'vue'
import TldrComponent from '@/components/tldr/TldrComponent.vue'
import type { Keypoint } from '@tuxcy/types'
import ImageComponent from '@/components/image/ImageComponent.vue'

const code = ref(`<div>
    <a>Link</a>
</div>
`)
</script>

<style scoped>
.article-container {
  width: calc(100% - 50px);
  padding-bottom: 20px;
}
</style>
