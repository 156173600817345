import type { ImpressionType } from '@/ImpressionType'

export const impressionDataIntroduction: ImpressionType = {
  title: 'Impressions',
  impressionContent: `
        <p>
          Pensées mal branlées,<br />
          Torchons de papier.<br />
          Toiles dépecées<br />
          Dépourvu de substances,<br />
          Marquées par l'absence,<br />
          D'une quelconque intention,<br />
          J'ai nommé ... "impressions" !<br />
        </p>
  `,
  illustrationCaption: 'My mental desk',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/impression-paper-1.jpg',
  noteEntries: ['Écrit sous la pluie...']
}
