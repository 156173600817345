import LateralNavbarComponent from '@/components/sidebar/LateralNavbarComponent.vue'
import TldrComponent from '@/components/tldr/TldrComponent.vue'
import BrandComponent from '@/components/brand/BrandComponent.vue'
import ImageComponent from '@/components/image/ImageComponent.vue'
import WelcomeImpressionSites from '@/components/impressions/WelcomeImpressionSites.vue'
import { ImpressionsEnum } from '@/components/impressions/ImpressionsEnum'
import SidebarEntry from '@/components/sidebar/SidebarEntry.vue'
import AboutImpressionSites from '@/components/impressions/AboutImpressionSites.vue'
import type { ImpressionAppStore } from '@/store/ImpressionAppStore'
import ImpressionNotFound from '@/components/impressions/ImpressionNotFound.vue'
import ImpressionNavigationComponent from '@/components/impression-navigation/ImpressionNavigationComponent.vue'
import ImpressionGeneric from '@/components/impressions/ImpressionGeneric.vue'
import { impressionDataBratMuse } from '@/impressions/ImpressionDataBratMuse'
import { impressionDataIntroduction } from '@/impressions/ImpressionDataIntroduction'
import { impressionDataPlane } from '@/impressions/ImpressionDataPlane'
import { impressionDataChild } from '@/impressions/ImpressionDataChild'
import { impressionDataPlaneProductivity } from '@/impressions/ImpressionDataPlaneProductivity'
import HeaderNavbarComponent from '@/components/sidebar/HeaderNavbarComponent.vue'
import { impressionDataTrainCrossroads } from '@/impressions/ImpressionDataTrainCrossroads'
import { impressionDataHopeBubble } from '@/impressions/ImpressionDataHopeBubble'
import { impressionDataWordButton } from '@/impressions/ImpressionDataWordButton'
import { impressionDataCoupleHolidays } from '@/impressions/ImpressionDataCoupleHolidays'
import { impressionDataAuRevoir } from '@/impressions/ImpressionDataAuRevoir'
import { impressionDataBoulimie } from '@/impressions/ImpressionDataBoulimie'
import { impressionDataCapsule } from '@/impressions/ImpressionDataCapsule'
import { impressionDataMonolog } from '@/impressions/ImpressionDataMonolog'
import { impressionDataPlocPloc } from '@/impressions/ImpressionDataPlocPloc'
import { impressionDataPointille } from '@/impressions/ImpressionDataPointille'
import { impressionDataColoc } from '@/impressions/ImpressionDataColoc'
import { impressionDataSacDeNoeud } from '@/impressions/ImpressionDataSacDeNoeud'
import { impressionDataEruption } from '@/impressions/ImpressionDataEruption'
import { impressionDataEscalier } from '@/impressions/ImpressionDataEscalier'

export enum ComponentsEnum {
  IMPRESSION_NAVIGATION = 'impression-navigation-component',
  LATERAL_NAVBAR = 'lateral-navbar-component',
  HEADER_NAVBAR = 'header-navbar-component',
  SIDEBAR_ENTRY = 'sidebar-entry',
  TLDR = 'tldr-component',
  BRAND = 'brand-component',
  IMAGE = 'image-component'
}

const components: {
  alias: string
  impl: any
  type: 'component' | 'directive' | 'impression'
  data?: any
}[] = [
  {
    alias: ComponentsEnum.LATERAL_NAVBAR,
    impl: LateralNavbarComponent,
    type: 'component'
  },
  {
    alias: ComponentsEnum.HEADER_NAVBAR,
    impl: HeaderNavbarComponent,
    type: 'component'
  },
  {
    alias: ComponentsEnum.TLDR,
    impl: TldrComponent,
    type: 'component'
  },
  {
    alias: ComponentsEnum.BRAND,
    impl: BrandComponent,
    type: 'component'
  },
  {
    alias: ComponentsEnum.IMAGE,
    impl: ImageComponent,
    type: 'component'
  },
  {
    alias: ComponentsEnum.SIDEBAR_ENTRY,
    impl: SidebarEntry,
    type: 'component'
  },
  {
    alias: ComponentsEnum.IMPRESSION_NAVIGATION,
    impl: ImpressionNavigationComponent,
    type: 'component'
  },
  // impression
  {
    alias: ImpressionsEnum.IMP_INTRODUCTION,
    impl: ImpressionGeneric,
    data: impressionDataIntroduction,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_BRAT_MUSE,
    impl: ImpressionGeneric,
    data: impressionDataBratMuse,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_PLANE,
    impl: ImpressionGeneric,
    data: impressionDataPlane,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_CHILD,
    impl: ImpressionGeneric,
    data: impressionDataChild,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_PLANE_PRODUCTIVITY,
    impl: ImpressionGeneric,
    data: impressionDataPlaneProductivity,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_TRAIN_CROSSROADS,
    impl: ImpressionGeneric,
    data: impressionDataTrainCrossroads,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_HOPE_BUBBLE,
    impl: ImpressionGeneric,
    data: impressionDataHopeBubble,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_WORD_BUTTON,
    impl: ImpressionGeneric,
    data: impressionDataWordButton,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_COUPLE_HOLIDAY,
    impl: ImpressionGeneric,
    data: impressionDataCoupleHolidays,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_AU_REVOIR,
    impl: ImpressionGeneric,
    data: impressionDataAuRevoir,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_BOULIMIE,
    impl: ImpressionGeneric,
    data: impressionDataBoulimie,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_CAPSULE,
    impl: ImpressionGeneric,
    data: impressionDataCapsule,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_MONOLOG,
    impl: ImpressionGeneric,
    data: impressionDataMonolog,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_PLOC_PLOC,
    impl: ImpressionGeneric,
    data: impressionDataPlocPloc,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_POINTILLE,
    impl: ImpressionGeneric,
    data: impressionDataPointille,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_COLOC,
    impl: ImpressionGeneric,
    data: impressionDataColoc,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_SAC_DE_NOEUD,
    impl: ImpressionGeneric,
    data: impressionDataSacDeNoeud,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_ERUPTION,
    impl: ImpressionGeneric,
    data: impressionDataEruption,
    type: 'impression'
  },
  {
    alias: ImpressionsEnum.IMP_ESCALIER,
    impl: ImpressionGeneric,
    data: impressionDataEscalier,
    type: 'impression'
  },
  // welcome
  {
    alias: ImpressionsEnum.WELCOME,
    impl: WelcomeImpressionSites,
    type: 'component'
  },
  // about
  {
    alias: ImpressionsEnum.ABOUT,
    impl: AboutImpressionSites,
    type: 'component'
  },
  // not found
  {
    alias: ImpressionsEnum.NOT_FOUND,
    impl: ImpressionNotFound,
    type: 'component'
  }
]

export function registerUI(app: any, store: ImpressionAppStore) {
  components.forEach(function (item) {
    switch (item.type) {
      case 'impression':
      case 'component':
        app.component(item.alias, item.impl)
        break
      case 'directive':
        app.directive(item.alias)
        break
    }
  })
}

export function registerImpressions(store: ImpressionAppStore) {
  components
    .filter((item) => item.type === 'impression')
    .forEach(function (item, index) {
      store
        .getImpressionModule()
        .registerImpression({ id: index, impl: item.impl, alias: item.alias, data: item.data })
    })
}
