import { ComponentUtilsImpl } from '@/utils/ComponentUtilsImpl'
import type { ComponentUtils } from '@tuxcy/types'
import { FR_I18N_KEYS } from '@/assets/i18n/fr'

export class MockedComponentUtils extends ComponentUtilsImpl implements ComponentUtils {
  constructor() {
    super()
    console.warn('!!! MockedComponentUtils is enabled, this code should not be in production !!!')
  }
  $i18n(key: string): string {
    return FR_I18N_KEYS[key] || key
  }
}
