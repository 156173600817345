import type { ImpressionType } from '@/ImpressionType'

export const impressionDataColoc: ImpressionType = {
  title: 'Colocataire',
  impressionContent: `
<p>
On se ferme lentement à l'autre,<br/>
Quand on se mue en ersatz de soi.<br/>
Quand les rires succèdent aux doutes,<br/>
De n'être aimé que pour ça croûte.<br/>
<br/>
C'est avoir deux visages,<br/>
Colocataire ombrageux qui se fuient,<br/>
Dans sa peau et son être un fruit pourrit,<br/>
Mais ouvert, à l'intense comme à l'ennui.<br/>
<br/>
Au "qui suis-je ?" succéde un "que puis-je être ?".<br/>
Comme une identité que l'on ne peut exprimer.<br/>
Péché défendu que l'on ne peut partager.<br/>
On vois doucement nos liens se dénouer.<br/>
On ne veux prendre le risque d'être aimé.<br/>
<br/>
C'est se maudire d'être soit,<br/>
Une goutte d'encre sur de la soie,<br/>
Qui gorge les mailles de sa couleur,<br/>
Avec effroi, suscite la peur,<br/>
De tacher, d'imprégner, de marquer...<br/>
<br/>
C'est sentir que l'on ne peut "être".<br/>
Ni même se démettre,<br/>
De ce que nous sommes.<br/>
Un morceau de vie que l'on assomme,<br/>
De précepte monté en images,<br/>
Loin de faire de nous des sages.<br/>
Mais des êtres en nage.<br/>
Qui enrage.<br/>
</p>
  `,
  illustrationCaption: 'Janus',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/coloc-1.jpg',
  noteEntries: [`Mais qu'est ce donc qu'une perception ?`]
}
