import type { ImpressionType } from '@/ImpressionType'

export const impressionDataMonolog: ImpressionType = {
  title: 'Monologue',
  impressionContent: `
<p>
Silence de verre,<br/>
Fragile poche d'air,<br/>
Qui éclate dans l'écho,<br/>
D'une discussion en mono.<br/>
<br/>
Oppressante absence,<br/>
D'un confident,<br/>
Qui, amant,<br/>
Enferme nos secrets,<br/>
Mis en sécurité.<br/>
<br/>
Et ce geste,<br/>
Ce son,<br/>
Cette démarche,<br/>
De s'entendre parler,<br/>
Pour mieux se calmer,<br/>
Me renvoi au reflet,<br/>
Que je voudrai déchiré,<br/>
De ce frère damné,<br/>
Dont je vois les traits,<br/>
Chaque matin dessiné.<br/>
Hanté a jamais.<br/>
<br/>
Posé son regard,<br/>
Comme on caresse un souvenir,<br/>
Sentir sa voix,<br/>
Qui nous échappe ou respire,<br/>
A la gorge une peur,<br/>
Et une douleur,<br/>
Qui éclate, en sommeil,<br/>
Dans ces moments de grêle,<br/>
Ou le doute s'abat,<br/>
Tapisse d'effroi,<br/>
Nos pensées et nos rêves,<br/>
Comme la foudre tombe du ciel.<br/>
<br/>
On respire on s'étrangle,<br/>
On panique, on s'accroche,<br/>
On regarde au loin,<br/>
Oublier le lendemain,<br/>
Chercher le calme,<br/>
Faire campagne,<br/>
Sentir ses blessures,<br/>
Sous l'armure,<br/>
Sans compagne,<br/>
Au bagne ?<br/>
<br/>
S'accrocher,<br/>
Isoler,<br/>
Étouffer ?<br/>
Nier ?<br/>
<br/>
A quoi bon continuer,<br/>
Si comme un soufflet,<br/>
Ces phrases se muent en mots.<br/>
Qui a bon dos !<br/>
Car exprimer ses pensées,<br/>
A ce point synthétiser,<br/>
Ne profite guère au débat,<br/>
Qui est en moi.<br/>
<br/>
...<br/>
<br/>
Calme toi.. 
</p>
  `,
  illustrationCaption: 'Reflexion',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/monolog-reflection-1.jpg',
  noteEntries: ['Insomnie']
}
