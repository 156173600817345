import type { ImpressionType } from '@/ImpressionType'

export const impressionDataBoulimie: ImpressionType = {
  title: 'Boulimie',
  impressionContent: `
<p>
Dévorer des êtres,<br/>
Considérer peut-être,<br/>
Comme des morceaux de vie,<br/>
Que je consomme sans envie.<br/>
<br/>
Densifier l'existence,<br/>
Vivre l'effervescence,<br/>
Se tenir éloigné<br/>
De ce qui fait ma fierté.<br/>
<br/>
Consommer, consommer,<br/>
Sans discerner,<br/>
Sans discontinuer,<br/>
S'empiffrer,<br/>
Seul.<br/>
Penser à ma gueule.<br/>
<br/>
Envie de vieillir,<br/>
Arracher le futur<br/>
Débarrassé du passé,<br/>
Fuir le présent,<br/>
Le saturer d'instant.<br/>
<br/>
Je freine,<br/>
Cette peine qui me tient en haleine,<br/>
Morsure qui me prend au sang,<br/>
Sans discernement..<br/>
<br/>
Bête enragé,<br/>
Assiégé d'envie,<br/>
A l'excès suturé,<br/>
Ces plaies que je me crée.<br/>
Sature ! Torture !! ce que tu vie,<br/>
<br/>
Pour éviter de voir,<br/>
Détourner le regard,<br/>
Que tard le soir,<br/>
Épuisé,<br/>
Je cherche,<br/>
Liberté,<br/>
A sortir du passé..
</p>
  `,
  illustrationCaption: 'Miam',
  illustrationCopyright: 'Source: stability.ai',
  illustrationPath: '/img/impressions/boulimie-2.jpg',
  noteEntries: ['... éprouvant']
}
